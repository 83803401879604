exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lotte-besuch-js": () => import("./../../../src/pages/lotte/besuch.js" /* webpackChunkName: "component---src-pages-lotte-besuch-js" */),
  "component---src-pages-lotte-demenztherapie-js": () => import("./../../../src/pages/lotte/demenztherapie.js" /* webpackChunkName: "component---src-pages-lotte-demenztherapie-js" */),
  "component---src-pages-lotte-gesundheitclown-js": () => import("./../../../src/pages/lotte/gesundheitclown.js" /* webpackChunkName: "component---src-pages-lotte-gesundheitclown-js" */),
  "component---src-pages-lotte-index-js": () => import("./../../../src/pages/lotte/index.js" /* webpackChunkName: "component---src-pages-lotte-index-js" */),
  "component---src-pages-lotte-lachen-js": () => import("./../../../src/pages/lotte/lachen.js" /* webpackChunkName: "component---src-pages-lotte-lachen-js" */),
  "component---src-pages-lotte-lotte-js": () => import("./../../../src/pages/lotte/lotte.js" /* webpackChunkName: "component---src-pages-lotte-lotte-js" */),
  "component---src-pages-lotte-spiegelneuronen-js": () => import("./../../../src/pages/lotte/spiegelneuronen.js" /* webpackChunkName: "component---src-pages-lotte-spiegelneuronen-js" */),
  "component---src-pages-pomodora-index-js": () => import("./../../../src/pages/pomodora/index.js" /* webpackChunkName: "component---src-pages-pomodora-index-js" */),
  "component---src-pages-pomodora-kindertheater-js": () => import("./../../../src/pages/pomodora/kindertheater.js" /* webpackChunkName: "component---src-pages-pomodora-kindertheater-js" */),
  "component---src-pages-pomodora-pomodora-js": () => import("./../../../src/pages/pomodora/pomodora.js" /* webpackChunkName: "component---src-pages-pomodora-pomodora-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-press-presse-js": () => import("./../../../src/pages/press/presse.js" /* webpackChunkName: "component---src-pages-press-presse-js" */),
  "component---src-pages-press-referenzen-js": () => import("./../../../src/pages/press/referenzen.js" /* webpackChunkName: "component---src-pages-press-referenzen-js" */)
}

